import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { images } from '../assets/images';
import LanguageSelector from './LanguageSelector';

export default function Header() {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="fixed top-0 left-0 right-0 bg-brand-navy/80 backdrop-blur-sm z-50 border-b border-brand-blue/20">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="flex items-center gap-2"
          >
            <img src={images.logo} alt="istavrit" className="h-8 w-auto invert" />
          </motion.div>
          
          <div className="flex items-center gap-4">
            <nav className="hidden md:flex items-center gap-8">
              <motion.a 
                href="#services" 
                className="text-gray-300 hover:text-brand-accent transition-colors"
                whileHover={{ scale: 1.05 }}
              >
                {t('header.services')}
              </motion.a>
              <motion.a 
                href="#projects" 
                className="text-gray-300 hover:text-brand-accent transition-colors"
                whileHover={{ scale: 1.05 }}
              >
                {t('header.projects')}
              </motion.a>
              <motion.a 
                href="#contact" 
                className="text-gray-300 hover:text-brand-accent transition-colors"
                whileHover={{ scale: 1.05 }}
              >
                {t('header.contact')}
              </motion.a>
            </nav>
            <LanguageSelector />
            <button 
              className="md:hidden text-gray-300 hover:text-brand-accent"
              onClick={toggleMenu}
            >
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <motion.div
          initial={false}
          animate={{ height: isMenuOpen ? 'auto' : 0, opacity: isMenuOpen ? 1 : 0 }}
          className={`md:hidden overflow-hidden ${isMenuOpen ? 'border-t border-brand-blue/20' : ''}`}
        >
          <nav className="py-4 flex flex-col gap-4">
            <a 
              href="#services" 
              className="text-gray-300 hover:text-brand-accent transition-colors px-4 py-2"
              onClick={toggleMenu}
            >
              {t('header.services')}
            </a>
            <a 
              href="#projects" 
              className="text-gray-300 hover:text-brand-accent transition-colors px-4 py-2"
              onClick={toggleMenu}
            >
              {t('header.projects')}
            </a>
            <a 
              href="#contact" 
              className="text-gray-300 hover:text-brand-accent transition-colors px-4 py-2"
              onClick={toggleMenu}
            >
              {t('header.contact')}
            </a>
          </nav>
        </motion.div>
      </div>
    </header>
  );
}