import speakerAiInput from './speaker-ai-input.png';
import speakerAiChat from './speaker-ai-chat.png';
import logo from './fish-logo.svg';
import dolunaiInput from './dolunai-input.png';
import dolunaiProfile from './dolunai-profile.png';
import moonZodiac from './moon-zodiac.png';

export const images = {
  speakerAiInput,
  speakerAiChat,
  logo,
  dolunaiInput,
  dolunaiProfile,
  moonZodiac
} as const;