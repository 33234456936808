import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FaAws, FaCloud, FaRobot } from 'react-icons/fa';
import { SiGooglecloud } from 'react-icons/si';
import { images } from '../assets/images';

export default function Hero() {
  const { t } = useTranslation();

  return (
    <div className="relative overflow-hidden bg-brand-navy pt-16">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <motion.div 
            className="flex justify-center mb-8"
            whileHover={{ scale: 1.1 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <img src={images.logo} alt="istavrit" className="h-24 w-auto invert" />
          </motion.div>
          <h1 className="text-4xl font-bold tracking-tight text-brand-accent-light sm:text-6xl">
            istavrit
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            {t('hero.title')}
          </p>
          <div className="mt-10 flex justify-center gap-8 text-5xl text-gray-400">
            <motion.div whileHover={{ scale: 1.2, color: '#8087E5' }}>
              <FaRobot />
            </motion.div>
            <motion.div whileHover={{ scale: 1.2, color: '#8087E5' }}>
              <FaAws />
            </motion.div>
            <motion.div whileHover={{ scale: 1.2, color: '#8087E5' }}>
              <SiGooglecloud />
            </motion.div>
            <motion.div whileHover={{ scale: 1.2, color: '#8087E5' }}>
              <FaCloud />
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}