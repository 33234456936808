import { motion } from 'framer-motion';
import { FaCode, FaDatabase, FaMicrophone, FaStar, FaArrowRight, FaRobot, FaPen } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function Projects() {
  const { t } = useTranslation();

  const projects = [
    {
      title: t('projects.speakerAI.title'),
      description: t('projects.speakerAI.description'),
      link: "https://www.speakeragency.com.tr/yapay-zeka-asistani",
      tech: ["RAG", "Vector DB", "Whisper", "LLM"],
      features: [
        t('projects.speakerAI.features.nlp'),
        t('projects.speakerAI.features.matching'),
        t('projects.speakerAI.features.video'),
        t('projects.speakerAI.features.multilingual')
      ],
      implementation: [
        {
          icon: <FaDatabase className="text-3xl text-blue-500" />,
          title: t('projects.speakerAI.implementation.vectorDb.title'),
          description: t('projects.speakerAI.implementation.vectorDb.description')
        },
        {
          icon: <FaRobot className="text-3xl text-green-500" />,
          title: t('projects.speakerAI.implementation.generativeAi.title'),
          description: t('projects.speakerAI.implementation.generativeAi.description')
        },
        {
          icon: <FaMicrophone className="text-3xl text-purple-500" />,
          title: t('projects.speakerAI.implementation.speechToText.title'),
          description: t('projects.speakerAI.implementation.speechToText.description')
        }
      ]
    },
    {
      title: t('projects.dolunAI.title'),
      description: t('projects.dolunAI.description'),
      link: "https://dolunai.space",
      tech: ["LLM", "AI Avatar", "Social Media Integration", "Astrology APIs"],
      features: [
        t('projects.dolunAI.features.birthChart'),
        t('projects.dolunAI.features.horoscope'),
        t('projects.dolunAI.features.lifestyle'),
        t('projects.dolunAI.features.social')
      ],
      implementation: [
        {
          icon: <FaStar className="text-3xl text-yellow-500" />,
          title: t('projects.dolunAI.implementation.astrology.title'),
          description: t('projects.dolunAI.implementation.astrology.description')
        },
        {
          icon: <FaCode className="text-3xl text-purple-500" />,
          title: t('projects.dolunAI.implementation.avatar.title'),
          description: t('projects.dolunAI.implementation.avatar.description')
        },
        {
          icon: <FaPen className="text-3xl text-green-500" />,
          title: t('projects.dolunAI.implementation.content.title'),
          description: t('projects.dolunAI.implementation.content.description')
        }
      ]
    }
  ];

  return (
    <div className="py-24 bg-gray-50" id="projects">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-12 text-center">
            {t('projects.title')}
          </h2>
          <div className="grid gap-8 lg:grid-cols-2">
            {projects.map((project, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="bg-white p-8 rounded-xl shadow-lg flex flex-col"
              >
                <div className="mb-6">
                  <h3 className="text-2xl font-semibold">
                    {project.title}
                  </h3>
                </div>

                <div className="mb-8">
                  <p className="text-gray-600">
                    {project.description}
                  </p>
                </div>

                <div className="mb-8">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {project.implementation.map((item, index) => (
                      <div key={index} className="bg-gray-50 rounded-lg p-4 flex flex-col h-full">
                        <div className="flex items-center gap-3 mb-2">
                          {item.icon}
                          <h4 className="font-semibold text-sm">{item.title}</h4>
                        </div>
                        <p className="text-sm text-gray-700">
                          {item.description}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mb-8">
                  <h4 className="font-semibold mb-4">{t('projects.features.title')}</h4>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    {project.features.map((feature, index) => (
                      <li key={index} className="flex items-center gap-2 text-gray-700">
                        <span className="w-2 h-2 bg-blue-600 rounded-full"></span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="mb-8">
                  <div className="flex flex-wrap gap-2">
                    {project.tech.map((tech, index) => (
                      <span
                        key={index}
                        className="px-3 py-1 bg-blue-50 text-blue-600 text-sm rounded-full"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>

                <div className="mt-auto pt-4 flex justify-center">
                  {project.link && (
                    <motion.a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center gap-2 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 400 }}
                    >
                      {t('projects.speakerAI.viewProject')} <FaArrowRight className="text-sm" />
                    </motion.a>
                  )}
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}