import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FaMapMarkerAlt, FaEnvelope, FaArrowRight } from 'react-icons/fa';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <div className="py-24 bg-gradient-to-b from-brand-navy to-brand-blue-dark" id="contact">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-brand-accent-light sm:text-4xl mb-4">
              {t('contact.title')}
            </h2>
            <p className="text-lg text-gray-300 mb-12 max-w-2xl mx-auto">
              {t('contact.subtitle')}
            </p>
          </motion.div>

          <div className="max-w-2xl mx-auto mb-12">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-left bg-gradient-to-br from-brand-blue/30 to-brand-blue-dark/50 p-8 rounded-2xl border border-brand-accent/20 backdrop-blur-sm"
            >
              <h3 className="font-semibold text-xl mb-6 text-brand-accent-light">{t('contact.info.title')}</h3>
              <div className="space-y-6">
                <motion.p 
                  className="flex items-start gap-4 text-gray-300"
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <FaMapMarkerAlt className="text-rose-400 mt-1 text-xl" />
                  <span>
                    <strong className="text-brand-accent-light block mb-1">{t('contact.info.address.name')}</strong>
                    {t('contact.info.address.street')}<br />
                    {t('contact.info.address.city')}<br />
                    {t('contact.info.address.country')}
                  </span>
                </motion.p>
                <motion.p 
                  className="flex items-center gap-4"
                  whileHover={{ x: 10 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <FaEnvelope className="text-brand-accent text-xl" />
                  <a 
                    href="mailto:info@istavrit.dev" 
                    className="text-gray-300 hover:text-brand-accent transition-colors"
                  >
                    info@istavrit.dev
                  </a>
                </motion.p>
              </div>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <motion.a
              href="mailto:info@istavrit.dev"
              className="inline-flex items-center gap-2 bg-gradient-to-r from-rose-500 to-rose-600 text-white px-8 py-4 rounded-lg text-lg font-medium shadow-lg shadow-rose-500/20 hover:shadow-xl hover:shadow-rose-500/30 transition-all hover:-translate-y-0.5"
              whileHover={{ scale: 1.05 }}
              transition={{ type: "spring", stiffness: 400 }}
            >
              {t('contact.cta')} <FaArrowRight className="text-sm" />
            </motion.a>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}