import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
import { useState } from 'react';

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'tr', name: 'Türkçe' }
  ];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <motion.button
        whileHover={{ scale: 1.05 }}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 text-gray-300 hover:text-brand-accent transition-colors"
      >
        <FaGlobe />
        <span>{languages.find(lang => lang.code === i18n.language)?.name || 'English'}</span>
      </motion.button>
      
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute right-0 mt-2 py-2 w-32 bg-brand-blue rounded-lg shadow-xl border border-brand-accent/20"
        >
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className={`w-full px-4 py-2 text-left hover:bg-brand-blue-light transition-colors ${
                i18n.language === lang.code ? 'text-brand-accent' : 'text-gray-300'
              }`}
            >
              {lang.name}
            </button>
          ))}
        </motion.div>
      )}
    </div>
  );
}