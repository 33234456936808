export const tr = {
  header: {
    services: "Servisler",
    projects: "Projeler",
    contact: "İletişim"
  },
  hero: {
    title: "Yapay Zeka Çözümleri ve Veri Bilimi Danışmanlığı"
  },
  services: {
    title: "Servisler",
    chatbots: {
      title: "Yapay Zeka Sohbet Robotları",
      description: "Gelişmiş NLP ve makine öğrenimi ile güçlendirilmiş özel sohbet robotu çözümleri"
    },
    rag: {
      title: "RAG Sistemleri",
      description: "Gelişmiş yapay zeka uygulamaları için Retrieval Augmented Generation implementasyonları"
    },
    cloud: {
      title: "Bulut Çözümleri",
      description: "AWS ve GCP platformlarında ölçeklenebilir yapay zeka altyapısı"
    },
    ai: {
      title: "Yapay Zeka Uygulamaları",
      description: "İş otomasyonu ve dijital dönüşüm için özel yapay zeka destekli uygulamalar"
    }
  },
  projects: {
    title: "Öne Çıkan Projeler",
    features: {
      title: "Özellikler"
    },
    speakerAI: {
      title: "Speaker AI Asistanı",
      description: "Etkinlik organizatörlerini en uygun konuşmacılarla eşleştiren akıllı bir öneri sistemi. Speaker Agency için geliştirilen bu yapay zeka asistanı, RAG, LLM'ler ve konuşma-metin dönüşümü dahil gelişmiş yapay zeka teknolojilerini kullanır.",
      features: {
        nlp: "Doğal dil işleme girişi",
        matching: "Akıllı konuşmacı eşleştirme",
        video: "Video içerik analizi",
        multilingual: "Çok dilli destek (TR/EN)"
      },
      implementation: {
        vectorDb: {
          title: "Vektör Veritabanı",
          description: "Verimli bilgi erişimi için RAG implementasyonu"
        },
        generativeAi: {
          title: "Üretken Yapay Zeka",
          description: "Doğal dil anlama ve yanıt üretimi için LLM"
        },
        speechToText: {
          title: "Konuşma-Metin",
          description: "Video içerik analizi için konuşma-metin dönüşümü"
        }
      },
      viewProject: "Projeyi Görüntüle"
    },
    dolunAI: {
      title: "DolunAI",
      description: "Kişiselleştirilmiş doğum haritası okumaları ve günlük burç tahminleri üreten gelişmiş bir astroloji uygulaması. X (Twitter) üzerinde yaşam tarzı ve ilişkiler hakkında günlük içgörüler paylaşan bir yapay zeka avatarı içerir.",
      features: {
        birthChart: "Doğum haritası oluşturma",
        horoscope: "Kişiselleştirilmiş burç okumaları",
        lifestyle: "Yapay zeka destekli yaşam tarzı tavsiyeleri",
        social: "Sosyal medya otomasyonu"
      },
      implementation: {
        astrology: {
          title: "Astroloji Motoru",
          description: "Doğru doğum haritası hesaplamaları için gelişmiş algoritmalar"
        },
        avatar: {
          title: "Yapay Zeka Avatarı",
          description: "Dolunay Varlı olarak kişiselleştirilmiş sosyal medya varlığı"
        },
        content: {
          title: "İçerik Üretimi",
          description: "Yapay zeka destekli burç ve yaşam tarzı içerik oluşturma"
        }
      }
    }
  },
  contact: {
    title: "Birlikte Çalışalım",
    subtitle: "İşletmenizi en son yapay zeka çözümleriyle dönüştürmeye hazır mısınız? İletişime geçin ve hedeflerinize nasıl ulaşabileceğinizi birlikte tartışalım.",
    info: {
      title: "İletişim Bilgileri",
      address: {
        name: "Onur Timur",
        street: "İdealtepe mahallesi Dik sokak 13/2",
        city: "Maltepe İstanbul 34841",
        country: "Türkiye"
      }
    },
    cta: "İletişime Geç"
  }
};