import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FaRobot, FaDatabase, FaCloud, FaBrain } from 'react-icons/fa';

export default function Services() {
  const { t } = useTranslation();

  const services = [
    {
      icon: <FaRobot className="text-4xl text-blue-400" />,
      title: t('services.chatbots.title'),
      description: t('services.chatbots.description'),
      bgColor: "bg-blue-900/30",
      borderColor: "border-blue-500/20",
      hoverBg: "hover:bg-blue-900/50"
    },
    {
      icon: <FaDatabase className="text-4xl text-emerald-400" />,
      title: t('services.rag.title'),
      description: t('services.rag.description'),
      bgColor: "bg-emerald-900/30",
      borderColor: "border-emerald-500/20",
      hoverBg: "hover:bg-emerald-900/50"
    },
    {
      icon: <FaCloud className="text-4xl text-purple-400" />,
      title: t('services.cloud.title'),
      description: t('services.cloud.description'),
      bgColor: "bg-purple-900/30",
      borderColor: "border-purple-500/20",
      hoverBg: "hover:bg-purple-900/50"
    },
    {
      icon: <FaBrain className="text-4xl text-rose-400" />,
      title: t('services.ai.title'),
      description: t('services.ai.description'),
      bgColor: "bg-rose-900/30",
      borderColor: "border-rose-500/20",
      hoverBg: "hover:bg-rose-900/50"
    }
  ];

  return (
    <div className="py-24 bg-brand-navy" id="services">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-bold text-brand-accent-light sm:text-4xl mb-12 text-center">
            {t('services.title')}
          </h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`text-center p-6 rounded-xl transition-colors border ${service.bgColor} ${service.borderColor} ${service.hoverBg}`}
              >
                <div className="mb-4 flex justify-center">{service.icon}</div>
                <h3 className="text-xl font-semibold mb-3 text-brand-accent-light">{service.title}</h3>
                <p className="text-gray-300">{service.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  );
}